import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-391ad028"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "upload-box"
};
const _hoisted_2 = {
  key: 1,
  style: {
    "background": "#fff",
    "color": "#1890ff",
    "line-height": "30px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_a_button = _resolveComponent("a-button");

  const _component_a_upload = _resolveComponent("a-upload");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_a_upload, {
    "file-list": $setup.fileList,
    "onUpdate:file-list": _cache[0] || (_cache[0] = $event => $setup.fileList = $event),
    name: "uploadFile",
    action: $props.action,
    "before-upload": $setup.beforeUpload,
    onChange: $setup.handleChange,
    headers: $setup.uploadHeaders,
    showUploadList: false,
    class: "uploadExl"
  }, {
    default: _withCtx(() => [$props.isShowBtn ? (_openBlock(), _createBlock(_component_a_button, {
      key: 0,
      type: "primary"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($props.title), 1)]),
      _: 1
    })) : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($props.title), 1))]),
    _: 1
  }, 8, ["file-list", "action", "before-upload", "onChange", "headers"])]);
}