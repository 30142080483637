import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { computed, reactive, toRefs, ref, watch } from 'vue';
import Cookies from "js-cookie";
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';
export default {
  props: {
    title: {
      type: String,
      default: '批量导入'
    },
    action: {
      type: String,
      default: '/wlapi/employee/readExcel'
    },
    isShowBtn: {
      type: Boolean,
      default: true
    }
  },
  // 接收父组件的方法
  emits: ['isSuccess'],
  components: {
    LoadingOutlined,
    PlusOutlined
  },

  setup(props, context) {
    //图片上传
    const fileList = ref([]);
    const loading = ref(false);

    const handleChange = info => {
      // console.log(info)
      if (info.file.status === 'uploading') {
        loading.value = true;
        return;
      }

      if (info.file.status === 'done') {
        if (info.file.response.code == 200) {
          loading.value = false;

          _message.success('上传成功');

          context.emit('isSuccess', true);
        } else {
          _message.error(info.file.response.message);
        }
      }

      if (info.file.status === 'error') {
        loading.value = false;

        _message.error('上传失败');
      }
    };

    const uploadHeaders = computed(() => ({
      'token': Cookies.get('token'),
      'belongProject': Cookies.get('belongProject')
    }));

    const beforeUpload = file => {
      const isXlsxOrPdf = file.type === 'text/xml' || 'application/pdf';

      if (!isXlsxOrPdf) {
        _message.error('只能上传xlxs/pdf文件!');
      }

      return true;
    };

    return {
      handleChange,
      beforeUpload,
      fileList,
      loading,
      uploadHeaders
    };
  }

};